import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ConfigurationSettings = () => {
  return (
    <LayoutComponent>
      <h3>Configuration Settings</h3>
      <div className="testimonials">
        <h3>Note</h3>
        <p>
          You must be a site admin or project admin to manage th configuration
          settings for your project.
        </p>
      </div>
      <ol>
        <li>
          Click Configuration in the upper-right corner of the screen to access
          the configuration settings.
        </li>
        <li>
          There are three sections for you to customize: GENERAL, STATUSES,
          MODULES, and PRIORITIES.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default ConfigurationSettings
